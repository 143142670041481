import { MetaProRadar } from "./MetaProRadar";
import { Weight } from "./MetaProRadar/Weight";
import { OverallStats } from "./OverallStats";

import "./index.scss";

export const Analytics = () => {
  return (
    <section>
      <OverallStats />
      <div>
        <Weight />
        <MetaProRadar />
      </div>
    </section>
  );
};
