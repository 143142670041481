import "./index.scss";
import { Muscle } from "../../../../assets";

export const Content = () => {
  return (
    <div className="image-title-content-container">
      <div>
        <h1>Workout Progression</h1>
        <p>Contains visual graphs data for various workouts and comparison to the meta/pro level.</p>
      </div>
      <div>
        <img alt="" src={Muscle} />
        <img alt="" src={Muscle} />
      </div>
    </div>
  );
};
