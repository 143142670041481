import { Endurance, GraphIcon, Stamina, Strength } from "../../assets";

export interface IDashboardItem {
  image: string;
  name: string;
}

export const dashboard: IDashboardItem[] = [
  {
    image: GraphIcon,
    name: "Analytics",
  },
  {
    image: Strength,
    name: "Strength",
  },
  {
    image: Endurance,
    name: "Endurance",
  },
  {
    image: Stamina,
    name: "Stamina",
  },
];
