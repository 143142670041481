import { Content } from "./Content";

import "./index.scss";

export const ImageTitle = () => {
  return (
    <div className="image-title-container">
      <div className="background-waves"></div>
      <Content />
    </div>
  );
};
