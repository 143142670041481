import { Line, LineChart, ResponsiveContainer } from "recharts";

import "./index.scss";

export const Weight = () => {
  const fakeData = [
    {
      name: "Page A",
      uv: 500,
    },
    {
      name: "Page B",
      uv: 555,
    },
  ];

  return (
    <div>
      <h2>Weight</h2>
      <ResponsiveContainer height="100%">
        <LineChart data={fakeData}>
          <Line dataKey="uv" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
