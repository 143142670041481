import { Logo } from "./Logo";
import { Dashboard } from "./Dashboard";

import "./index.scss";

export const Menu = () => {
  return (
    <div className="menu-container">
      <Logo />
      <Dashboard />
    </div>
  );
};
