import { IWorkoutData } from "../data/Dean/workoutProgression";

export function GraphArrayBuilder(data: number[], metaData: number, proData: number) {
  const graphDataArray: { name: string; DeanValue: number; MetaValue: number; ProValue: number }[] = [];
  for (let i = 0; i < data.length; i++) {
    graphDataArray.push({
      name: `Month ${i + 1}`,
      DeanValue: data[i],
      MetaValue: metaData,
      ProValue: proData,
    });
  }
  return graphDataArray;
}

export function PercentMetaComparedProCalculator(dataArray: IWorkoutData[], isStamina?: boolean) {
  let percent = 0;
  for (let i = 0; i < dataArray.length; i++) percent += (isStamina ? dataArray[i].pro / dataArray[i].meta : dataArray[i].meta / dataArray[i].pro) * 100;
  return Math.round(percent / dataArray.length);
}

export function PercentAthleteComparedProCalculator(dataArray: IWorkoutData[], isStamina?: boolean) {
  let percent = 0;
  for (let i = 0; i < dataArray.length; i++) {
    const lastValue = dataArray[i].values[dataArray[i].values.length - 1];
    if (!lastValue) continue;
    percent += (isStamina ? dataArray[i].pro / lastValue : lastValue / dataArray[i].pro) * 100;
  }
  return Math.round(percent / dataArray.length);
}
