import { IWorkoutData, endurance } from "../../../data/Dean/workoutProgression";
import { GenericLineGraph } from "../../GenericLineGraph";

export const Endurance = () => {
  return (
    <section>
      <div className="graphs-container">
        {endurance.map((workoutData: IWorkoutData, index: number) => {
          return <GenericLineGraph key={index} workoutData={workoutData} />;
        })}
      </div>
    </section>
  );
};
