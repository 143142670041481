import { RadarGraph } from "./RadarGraph";
import { Title } from "./Title";

export const MetaProRadar = () => {
  return (
    <div>
      <Title />
      <RadarGraph />
    </div>
  );
};
