import { createContext, useState } from "react";

type ICategory = "ANALYTICS" | "STRENGTH" | "ENDURANCE" | "STAMINA";

interface IAppContext {
  selectedCategory: ICategory;
  setSelectedCategory: Function;
}

export const AppContext = createContext<IAppContext>({
  selectedCategory: "ANALYTICS",
  setSelectedCategory: () => {},
});

type Props = { children: React.ReactNode };

export const AppContextProvider = ({ children }: Props) => {
  const [selectedCategory, setSelectedCategory] = useState<any>("ANALYTICS");

  return <AppContext.Provider value={{ selectedCategory, setSelectedCategory }}>{children}</AppContext.Provider>;
};
