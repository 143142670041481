import { PolarAngleAxis, Radar, RadarChart, ResponsiveContainer } from "recharts";
import { PercentAthleteComparedProCalculator, PercentMetaComparedProCalculator } from "../../../../../utils/graphs";
import { endurance, stamina, strength } from "../../../../../data/Dean/workoutProgression";

import "./index.scss";
import { deanColor, metaColor, proColor } from "../../../../../styles/global";

export const RadarGraph = () => {
  const data = [
    {
      subject: "Strength",
      dean: PercentAthleteComparedProCalculator(strength),
      meta: PercentMetaComparedProCalculator(strength),
      pro: 100,
    },
    {
      subject: "Endurance",
      dean: PercentAthleteComparedProCalculator(endurance),
      meta: PercentMetaComparedProCalculator(endurance),
      pro: 100,
    },
    {
      subject: "Stamina",
      dean: PercentAthleteComparedProCalculator(stamina, true),
      meta: PercentMetaComparedProCalculator(stamina, true),
      pro: 100,
    },
  ];

  return (
    <div className="radar-graph-wrapper">
      <ResponsiveContainer height="100%">
        <RadarChart data={data}>
          <PolarAngleAxis dataKey="subject" />
          <Radar dataKey="pro" fill={proColor} fillOpacity={0.9} />
          <Radar dataKey="meta" fill={metaColor} fillOpacity={0.7} />
          <Radar dataKey="dean" fill={deanColor} fillOpacity={0.8} />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};
