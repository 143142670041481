import { AppContext } from "../../../context/AppContext";
import { useContext } from "react";
import { IDashboardItem, dashboard } from "../../../data/Menu/dashboard";

import "./index.scss";

export const Dashboard = () => {
  const { selectedCategory, setSelectedCategory } = useContext(AppContext);

  return (
    <div className="dashboard-container">
      <h5>Dashboard</h5>
      <div>
        {dashboard.map((item: IDashboardItem, index: number) => {
          return (
            <div
              key={index}
              className={selectedCategory === item.name.toUpperCase() ? "selected-dashboard-item" : "not-selected-hover"}
              onClick={() => setSelectedCategory(item.name.toUpperCase())}
            >
              <img alt="" src={item.image} />
              <span>{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
