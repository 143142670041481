export interface IWorkoutData {
  name: string;
  unit: string;
  meta: number;
  pro: number;
  values: number[];
}

export const weight: number[] = [115];

export const strength: IWorkoutData[] = [
  {
    name: "Bench Press",
    unit: "KG",
    meta: 98,
    pro: 169,
    values: [75],
  },
  {
    name: "Deadlift",
    unit: "KG",
    meta: 150,
    pro: 220,
    values: [80],
  },
  {
    name: "Squat",
    unit: "KG",
    meta: 130,
    pro: 185,
    values: [],
  },
  {
    name: "Pullups",
    unit: "",
    meta: 18,
    pro: 37,
    values: [0],
  },
  {
    name: "Dips",
    unit: "",
    meta: 20,
    pro: 49,
    values: [4],
  },
];

export const endurance: IWorkoutData[] = [
  {
    name: "Pushups",
    unit: "",
    meta: 51,
    pro: 99,
    values: [16],
  },
  {
    name: "Situps",
    unit: "",
    meta: 60,
    pro: 159,
    values: [18],
  },
  {
    name: "Squat",
    unit: "",
    meta: 57,
    pro: 150,
    values: [25],
  },
  {
    name: "Plank",
    unit: "S",
    meta: 172,
    pro: 300,
    values: [45],
  },
];

export const stamina: IWorkoutData[] = [
  {
    name: "Sprint 400M",
    unit: "S",
    meta: 89,
    pro: 70,
    values: [130],
  },
  {
    name: "Run 3KM",
    unit: "M",
    meta: 15,
    pro: 11,
    values: [23.01],
  },
  {
    name: "Run 5KM",
    unit: "M",
    meta: 23,
    pro: 18,
    values: [],
  },
  {
    name: "Run 10KM",
    unit: "M",
    meta: 51,
    pro: 42,
    values: [],
  },
];
