import "./index.scss";

interface IColorGuideLines {
  deanColor: string;
  metaColor: string;
  proColor: string;
}

export const ColorGuideLines = ({ deanColor, metaColor, proColor }: IColorGuideLines) => {
  return (
    <div className="color-guide-lines-container">
      <div>
        <div style={{ backgroundColor: deanColor }}></div>
        <span>Dean</span>
      </div>
      <div>
        <div style={{ backgroundColor: metaColor }}></div>
        <span>Meta</span>
      </div>
      <div>
        <div style={{ backgroundColor: proColor }}></div>
        <span>Pro</span>
      </div>
    </div>
  );
};
