import "./index.scss";
import { EmptyFace } from "../../../assets";

export const NoGraphData = () => {
  return (
    <div className="no-graph-data-container">
      <span>No Data</span>
      <img alt="" src={EmptyFace} />
    </div>
  );
};
