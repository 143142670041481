import { IWorkoutData, strength } from "../../data/Dean/workoutProgression";
import { GenericLineGraph } from "../GenericLineGraph";

export const Strength = () => {
  return (
    <section>
      <div className="graphs-container">
        {strength.map((workoutData: IWorkoutData, index: number) => {
          return <GenericLineGraph key={index} workoutData={workoutData} />;
        })}
      </div>
    </section>
  );
};
