import { IWorkoutData, endurance, stamina, strength } from "../../../../data/Dean/workoutProgression";
import { Stats } from "./Stats";

import "./index.scss";
import { Dumbbell, MountainRoad, RunningShoes } from "../../../../assets";

export interface IBarView {
  name: string;
  color: string;
  image: string;
}

export const OverallStats = () => {
  const categoryStats: IWorkoutData[][] = [strength, endurance, stamina];

  const barView: IBarView[] = [
    {
      name: "Strength",
      color: "#E76767",
      image: Dumbbell,
    },
    {
      name: "Endurance",
      color: "#EDAD4D",
      image: MountainRoad,
    },
    {
      name: "Stamina",
      color: "#C0E5D9",
      image: RunningShoes,
    },
  ];

  return (
    <div className="bar-graphs-container">
      {categoryStats.map((category: IWorkoutData[], index: number) => {
        return <Stats key={index} data={category} view={barView[index]} />;
      })}
    </div>
  );
};
