import "./index.scss";
import { RunningManLogo } from "../../../assets";

export const Logo = () => {
  return (
    <div className="logo-wrapper">
      <img alt="" src={RunningManLogo} />
      <span>uAthlete</span>
    </div>
  );
};
