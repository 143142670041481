import { IWorkoutData } from "../../data/Dean/workoutProgression";
import { deanColor, metaColor, proColor } from "../../styles/global";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GraphArrayBuilder } from "../../utils/graphs";

import { ColorGuideLines } from "./ColorGuideLines";
import { NoGraphData } from "./NoGraphData";

import "./index.scss";

interface IGenericLineGraph {
  workoutData: IWorkoutData;
}

export const GenericLineGraph = ({ workoutData }: IGenericLineGraph) => {
  const data = GraphArrayBuilder(workoutData.values, workoutData.meta, workoutData.pro);

  const graphStyle = {
    stroke: "#8996a4",
  };

  const lineStyle = {
    strokeWidth: 2,
    stroke: "rgb(98, 147, 255)",
    metaStroke: "rgb(229, 138, 0)",
    proStroke: "rgb(220, 38, 38)",
    dotRadius: 7,
  };

  return (
    <div className="generic-line-graph-card">
      <div className="title-and-progress">
        <h1>{workoutData.name}</h1>
        {workoutData.values.length > 0 && <ColorGuideLines deanColor={deanColor} metaColor={metaColor} proColor={proColor} />}
      </div>
      {workoutData.values.length > 0 ? (
        <ResponsiveContainer height="100%">
          <LineChart data={data} margin={{ top: 10, bottom: 30, right: 10 }}>
            <CartesianGrid strokeDasharray="1 5" strokeOpacity={0.5} vertical={false} />
            <XAxis dataKey="name" stroke={graphStyle.stroke} tickLine={false} axisLine={false} tickMargin={10} />
            <YAxis stroke={graphStyle.stroke} tickLine={false} axisLine={false} unit={workoutData.unit} />
            <Line
              type="monotone"
              dataKey="DeanValue"
              stroke={lineStyle.stroke}
              strokeWidth={lineStyle.strokeWidth}
              dot={false}
              activeDot={{ r: lineStyle.dotRadius, fill: lineStyle.stroke, strokeWidth: 1 }}
            />
            <Line
              type="monotone"
              dataKey="MetaValue"
              dot={false}
              stroke={lineStyle.metaStroke}
              strokeWidth={lineStyle.strokeWidth}
              activeDot={{ r: lineStyle.dotRadius, fill: lineStyle.metaStroke, strokeWidth: 1 }}
            />
            <Line
              type="monotone"
              dataKey="ProValue"
              dot={false}
              stroke={lineStyle.proStroke}
              strokeWidth={lineStyle.strokeWidth}
              activeDot={{ r: lineStyle.dotRadius, fill: lineStyle.proStroke, strokeWidth: 1 }}
            />
            <Tooltip
              cursor={{ stroke: graphStyle.stroke, opacity: 0.5 }}
              contentStyle={{
                backgroundColor: "rgb(19, 25, 32)",
                borderRadius: "5px",
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <NoGraphData />
      )}
    </div>
  );
};
