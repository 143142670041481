import { Bar, BarChart, ResponsiveContainer } from "recharts";
import { IWorkoutData } from "../../../../../data/Dean/workoutProgression";
import { IBarView } from "..";

interface IStats {
  data: IWorkoutData[];
  view: IBarView;
}

export const Stats = ({ data, view }: IStats) => {
  const fakeData = [
    {
      name: "page1",
      value: 40,
    },
    {
      name: "page2",
      value: 55,
    },
    {
      name: "page3",
      value: 23,
    },
    {
      name: "page3",
      value: 23,
    },
    {
      name: "page3",
      value: 23,
    },
  ];

  return (
    <div className="bar-stats-container">
      <div style={{ textAlign: "center" }}>Not Enough Data Yet !</div>

      {/* <div className="title">
        <div style={{ backgroundColor: view.color }}>
          <img alt="" src={view.image} />
        </div>
        <h5>{view.name}</h5>
      </div>
      <div className="bar-graph-wrapper">
        <ResponsiveContainer height="100%">
          <BarChart data={fakeData}>
            <Bar dataKey="value" fill={view.color} />
          </BarChart>
        </ResponsiveContainer>
      </div> */}
    </div>
  );
};
