import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

import { ImageTitle } from "./ImageTitle";
import { Analytics } from "./Analytics";
import { Strength } from "../Strength";
import { Endurance } from "./Endurance";
import { Stamina } from "./Stamina";

import "./index.scss";

export const Application = () => {
  const { selectedCategory } = useContext(AppContext);

  const categoryComponentRenderHandler = () => {
    switch (selectedCategory) {
      case "ANALYTICS":
        return <Analytics />;
      case "STRENGTH":
        return <Strength />;
      case "ENDURANCE":
        return <Endurance />;
      case "STAMINA":
        return <Stamina />;
      default:
        return null;
    }
  };

  return (
    <section className="application-module">
      <ImageTitle />
      <div>
        <h1>{selectedCategory}</h1>
      </div>
      {categoryComponentRenderHandler()}
    </section>
  );
};
