import { AppContextProvider } from "./context/AppContext";

import { Menu } from "./components/Menu";
import { Application } from "./components/Application";

import "./App.scss";

function App() {
  return (
    <AppContextProvider>
      <div className="app">
        <Menu />
        <Application />
      </div>
    </AppContextProvider>
  );
}

export default App;
