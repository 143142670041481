import { deanColor, metaColor, proColor } from "../../../../../styles/global";

import { ColorGuideLines } from "../../../../GenericLineGraph/ColorGuideLines";

import "./index.scss";

export const Title = () => {
  return (
    <div className="title">
      <h2>Comparison</h2>
      <ColorGuideLines deanColor={deanColor} metaColor={metaColor} proColor={proColor} />
    </div>
  );
};
